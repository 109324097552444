import React from 'react';
import './Collage.css';

const images = [
  'memes/5814175221362575728.jpg',
  'memes/5814427666655331660.jpg',
  'memes/5814427666655331662.jpg',
  'memes/5814427666655331663.jpg',
  'memes/5814427666655331664.jpg',
  'memes/5814427666655331665.jpg',
  'memes/5814427666655331669.jpg',
  'memes/5814427666655331670.jpg',
  'memes/run.gif'
  // Add more image paths as needed
];

function Collage() {
  return (
    <div className="collage-container">
      {images.map((src, index) => (
        <img key={index} src={process.env.PUBLIC_URL + '/' + src} alt={`meme-${index}`} className="collage-image" />
      ))}
    </div>
  );
}

export default Collage;