import React from 'react';
import './SocialMenu.css';
import { FaTiktok, FaTwitter, FaTelegramPlane } from 'react-icons/fa';

function SocialMenu() {
  return (
    <div className="social-menu">
      <a href="https://www.tiktok.com/@opptheson?_t=8ql1OOzysv0&_r=1" target="_blank" rel="noopener noreferrer">
        <FaTiktok />
      </a>
      <a href="https://x.com/OppTheson" target="_blank" rel="noopener noreferrer">
        <FaTwitter />
      </a>
      <a href="https://t.me/Opp_CTOOO" target="_blank" rel="noopener noreferrer">
        <FaTelegramPlane />
      </a>
    </div>
  );
}

export default SocialMenu;