import React, { useState } from 'react';
import './HamburgerMenu.css';

function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="hamburger-menu">
      <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <div className={`menu-content ${isOpen ? 'open' : ''}`}>
        <a href="#home">Home</a>
        <a href="#story">Story</a>
        <a href="#memes">Memes</a>
        <a href="https://jup.ag/swap/SOL-6HGd1fRw6MVZMRNmDPB9XAjvcaYw4SbXP92YhUfYpump" target="_blank" rel="noopener noreferrer">Buy</a>
        <a href="https://dexscreener.com/solana/9e73exubdk5wht8tceshkiqvsurcw7d1tt1relnrqbet" target="_blank" rel="noopener noreferrer">Chart</a>
        <a href="https://t.me/Opp_CTOOO" target="_blank" rel="noopener noreferrer">Telegram</a>
      </div>
    </div>
  );
}

export default HamburgerMenu;