import React from 'react';
import picGm from './opp_main_gm.webp';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';
import HamburgerMenu from './HamburgerMenu';
import SocialMenu from './SocialMenu';
import Collage from './Collage';

function App() {
  return (
    <div className="App">
      <div id="home">
        <div className="mainImgContainer">
          <HamburgerMenu />
          <SocialMenu />
          <div className="square-bubble">
            <span className="tinyFont">6HGd1fRw6MVZMRNmDPB9XAjvcaYw4SbXP92YhUfYpump</span>
          </div>
        </div>
      </div>
      <div className="spacer" />
      <div id="story">
        <p><b>$OPP - The Story</b></p>
        <p className="story"><img src="./5820996162004567527.jpg" alt="story 1" /></p>
      </div>
      <div className="spacer" />
      <div id="memes">
        <p><b>$OPP - Memes</b></p>
        <Collage />
      </div>
    </div>
  );
}

export default App;